import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["addSiteButton"]

  initialize() {
    this.cocoonEvent()
    this.adContainer = $(this.element.firstChild).find('[data-ad-id="true"]')
  }

  cocoonEvent() {
    var controller = this
    $(controller.addSiteButtonTargets).
      data("association-insertion-method", 'append').
      data("association-insertion-node", function (link) {
        return link.closest(controller.data.get('container')).find(controller.data.get('form'))
      });
  }

  openModal(e) {
    e.preventDefault()
    var controller = this

    $.ajax({
      type: 'GET',
      url: Routes.organization_campaign_ad_previews_path(App.currentOrganizationId(), controller.campaignId),
      success: function () {
        $(document).on(controller.adSelectedEvent, function(event, adId) {
          controller.refreshAdPreview(adId)
          controller.detachAdSelectedListener()
        })

        $(document).on(controller.adModalClosedEvent, function(event) {
          controller.detachAdSelectedListener()
        })
      }
    });
  }

  detachAdSelectedListener() {
    $(document).off(this.adSelectedEvent)
  }

  refreshAdPreview(adId) {
    $(this.adContainer).val(adId)
    var coverContainer = $(this.element.firstChild).find('[data-is="cover-container"]')
    coverContainer.attr("data-selected-ad", adId)

    $.ajax({
      type: 'GET',
      url: Routes.organization_campaign_ad_preview_path(App.currentOrganizationId(), this.campaignId, adId),
      success: function () {
        App.initTooltipsAndPopovers()
      }
    });
  }

  get campaignId() {
    return this.data.get('campaign')
  }

  get adSelectedEvent() {
    return 'ad_chooser_modal.ad_selected'
  }

  get adModalClosedEvent() {
    return 'ad_chooser_modal.closed'
  }
}
