import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["startDate", "recaptureAds"]

  initialize() {
    let tomorrow = new Date()
    tomorrow.setHours(0,0,0,0)
    tomorrow.setDate(tomorrow.getDate() + 1)

    new DateTimePicker({
      datePickerSelector: '[data-is=scheduled-on-date-input]',
      hiddenInputSelector: '[data-is=scheduled-on-input]',
      initDate: tomorrow,
      timePickerSelector: '[data-is=scheduled-on-time-input]',
      datepicker: {
        startDate: tomorrow
      }
    })

    if(this.hasStartDateTarget) {
      this.bindDatePicker(this.startDateTarget)
    }

    if(this.hasRecaptureAdsTarget) {
      $(this.recaptureAdsTarget).closest('.recapture-ads-wrapper').hide()
    }
  }

  bindDatePicker(element) {
    // The next weekday after today
    let nextWeekday  = this.getNextWeekday(moment())

    $(element).daterangepicker({
      singleDatePicker: true,
      autoApply: true,
      showDropdowns: true,
      minDate: nextWeekday,
      // The result of tomorrow.format('YYYY') is a string representation of the year.
      // The second argument to parseInt, 10, specifies the radix (base) for the conversion.
      // E.g.: Today is 2024-06-04 => nextWeekday.format('YYYY') is 2024 => parseInt(nextWeekday.format('YYYY'),10) + 1 is 2025
      maxYear: parseInt(nextWeekday.format('YYYY'),10) + 1,
      isInvalidDate: function(date) {
        return date.isoWeekday() > 5
      },
    })
  }

  siteListModal(event) {
    event.preventDefault()
    
    $.ajax({
      url: Routes.new_static_modal_organization_site_lists_path(App.currentOrganizationId()),
      method: 'GET',
      success: function () {
        App.initSelectPickers()
      }
    })
  }

  getNextWeekday(date) {
    // Clone the original date + add 1 day
    let nextDate = moment(date).add(1, 'days');

    // Add 1 day to the current date until the weekday is between 1 (Monday) and 5 (Friday)
    while (nextDate.isoWeekday() > 5) {
        nextDate.add(1, 'days');
    }

    return nextDate;
  }

  toggleRecaptureAd(event) {
    // get the value of the selected option
    let value = event.target.value;
    // find the wrapper element
    let wrapper = $(this.recaptureAdsTarget).closest('.recapture-ads-wrapper')
    if (value === 'never') {
      wrapper.hide()
    } else {
      wrapper.show()
    }
  }
}
