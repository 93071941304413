import DropzoneController from "./dropzone_controller"

export default class extends DropzoneController {
  static targets = ["input"]

  acceptedFiles() {
    return "application/x-ole-storage,application/vnd.ms-excel,application/vnd.ms-office,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  }

  emptyQueue() {
    noty({ text: 'Please provide a XLS or XLSX file' })
  }

  submitForm(controller, target, formData) {
    var fields = $(controller.element).serializeArray()
    $.each(fields, function (i, field) {
      formData.append(field.name, field.value);
    });
  }
}
