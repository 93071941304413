import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "container"]

  initialize() {
    this.supported = this.data.get('supported')
    this.toggle()
  }

  toggle() {
    if (this.supported === 'true') {
      $(this.inputTarget).removeClass('disabled')
      this.setTooltipTitle('Copy the link to share this view')
    } else {
      $(this.inputTarget).addClass('disabled')
      this.setTooltipTitle('We currently do not support sharing this type of ad')
    }
  }

  copy() {
    var controller = this
    if (this.supported === 'true') {
      var campaignId = this.data.get('campaignId')
      var adId = this.data.get('adId')
      var url = Routes.organization_campaign_ad_preview_converter_path(App.currentOrganizationId(), campaignId, adId)

      $.ajax({
        url: url,
        method: 'POST',
        dataType: 'json',
        error: function (xhr) {
          noty({ text: xhr.responseJSON.error })
        },
        success: function (xhr) {
          navigator.clipboard.writeText(xhr.location)
          controller.setTooltipTitle('copied!')
          $(controller.containerTarget).tooltip('show')
          window.open(xhr.location, '_blank')
        }
      })
    }
  }

  setTooltipTitle(title) {
    $(this.containerTarget).tooltip({ animation: false }).attr('data-original-title', title)
  }
}
