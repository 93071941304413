import { Controller } from "stimulus"
import { LocalStorageHelper } from "../lib/local_storage_helper";

export default class extends Controller {
  static targets = ["wrapperType", "container", "osOptionsContainer" ]

  initialize() {
    if (this.setValueOnInit()) {
      this.setWrapperTypeValue(this.data)
    }
    this.setOsContainerVisibility(this.wrapperTypeTarget.value)
  }

  setValueOnInit() {
    return this.data.get('set-value-on-init') == 'true'
  }

  setWrapperTypeValue(data) {
    let value = LocalStorageHelper.storedValue(data)
    if (value != null) {
      $(this.wrapperTypeTarget).selectpicker('val', value)
    }
  }

  storeValue() {
    LocalStorageHelper.storeValue(this.data, this.wrapperTypeTarget.value)
  }

  toggleOsContainer(event) {
    this.setOsContainerVisibility(event.currentTarget.value)
  }

  setOsContainerVisibility(wrapperTypeValue) {
    if(this.hasOsOptionsContainerTarget) {
      if (wrapperTypeValue == 'os') {
        $(this.osOptionsContainerTarget).show()
      } else {
        $(this.osOptionsContainerTarget).hide()
      }
    }
  }

  displayContainer(event) {
    if ($(event.currentTarget).is(':checked')) {
      $(this.containerTarget).show()
    } else {
      $(this.containerTarget).hide()
    }
  }
}
