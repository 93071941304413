import { Controller } from "stimulus"
import { LocalStorageHelper } from "../lib/local_storage_helper"

export default class extends Controller {
  static targets = ["logo", "template"]

  initialize() {
    if (this.setValueOnInit()) {
      this.setPowerPointTemplateValue(this.data)
    }
    this.setLogoVisibility(this.getTemplateValue())
  }

  setValueOnInit() {
    return this.data.get('set-value-on-init') == 'true'
  }

  toggleLogo(event) {
    this.setLogoVisibility(event.currentTarget.value)
  }

  setLogoVisibility(templateValue) {
    if (templateValue == '') { // None
      $(this.logoTarget).show()
    } else {
      $(this.logoTarget).hide()
    }
  }

  setPowerPointTemplateValue(data) {
    let value = LocalStorageHelper.storedValue(data)
    if (value != null) {
      $(this.templateTarget).selectpicker('val', value)
    }
  }

  storeValue() {
    LocalStorageHelper.storeValue(this.data, this.getTemplateValue())
  }

  getTemplateValue() {
    if (this.hasTemplateTarget) {
      return this.templateTarget.value
    } else {
      return ''
    }
  }
}
