import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.dropzone = this.createDropzone()
    this.attachEvents(this.dropzone)
  }

  disconnect() {
    this.dropzone = null
  }

  createDropzone() {
    var controller = this;
    var acceptedFiles = this.acceptedFiles();

    return new Dropzone(controller.inputTarget, {
      autoProcessQueue: controller.data.get('autoProcessQueue'),
      autoQueue: this.autoQueue,
      dictDefaultMessage: controller.data.get("defaultMessage"),
      headers: { "X-CSRF-Token": this.getMetaValue("csrf-token") },
      maxFiles: this.maxFiles,
      parallelUploads: this.parallelUploads,
      acceptedFiles: acceptedFiles,
      uploadMultiple: this.maxFiles > 1 ? true : false,
      url: controller.data.get("url"),
      paramName: controller.data.get("paramName"),
      hiddenInputContainer: controller.data.get("inputContainer"),
      addRemoveLinks: true,
      timeout: this.timeout
    });
  }

  attachEvents(dz) {      
    this.attachRemovedFileEvents(dz)
    this.attachErrorEvents(dz)
    this.attachSuccessEvents(dz)
  }

  attachRemovedFileEvents(dz) {
    dz.on("removedfile", (file) => {
      if (file.hasOwnProperty('_destroy_path')) {
        $.ajax({
          type: 'DELETE',
          url: file._destroy_path,
          error: function(response) {
            noty({ text: response.message })
          },
          success: function(response) {
            noty({
              text: response.message,
              type: 'success'
            })
          }
        })
      }
    })
  }

  attachErrorEvents(dz) {
    dz.on("error", (file, errorMessage, xhr) => {
      if (typeof errorMessage === "object") {
        errorMessage = errorMessage.error.split(',').join(", ")
      }
      noty({text: errorMessage})
      dz.removeFile(file);
      $(".save").attr("disabled", false)
    })
  }

  attachSuccessEvents(dz) {
    if (this.maxFiles == 1) {
      dz.on("success", (file, response) => {
        noty({
          text: 'File successfully uploaded, redirecting',
          type: 'success'
        })
        window.location = response.location
      })
    } else {
      dz.on("successmultiple", (file, response) => {
        if (dz.getQueuedFiles().length == 0) {
          noty({
            text: dz.getAcceptedFiles().length + ' file(s) successfully uploaded',
            type: 'success'
          })

          if (response.location) {
            window.location = response.location
          }
        }
      })
    }
  }

  get timeout() {
    return this.getOptionOrUseDefault('timeout', 30000);
  }


  get maxFiles() {
    return this.getOptionOrUseDefault('maxFiles', 1);
  }

  get parallelUploads() {
    return this.getOptionOrUseDefault('parallelUploads', this.maxFiles);
  }

  get autoQueue(){
    return this.getOptionOrUseDefault('autoQueue', true);
  }

  getOptionOrUseDefault(optionName, defaultValue) {
    return (this.data.has(optionName) ? parseInt(this.data.get(optionName)) : defaultValue);
  }

  getMetaValue(name) {
    const element = this.findElement(document.head, `meta[name="${name}"]`)
    if (element) {
      return element.getAttribute("content")
    }
  }

  findElement(root, selector) {
    if (typeof root == "string") {
      selector = root
      root = document
    }
    return root.querySelector(selector)
  }

  submit(event) {
    event.preventDefault()
    var target = $(event.currentTarget)
    this.bindSubmitEvent(target)


    if (this.dropzone.getQueuedFiles().length > 0) {
      $(".save").attr("disabled", true)
      this.dropzone.processQueue()
    } else {
      this.emptyQueue()
    }
  }

  bindSubmitEvent(target) {
    var controller = this
    // send all the form data along with the file:
    this.dropzone.on("sending", (data, xhr, formData) => {
      controller.submitForm(controller, target, formData)
    })
  }

  acceptedFiles() {
    console.log('Implement in subclass: accepted files')
  }

  emptyQueue() {
    console.log('Implement in subclass: This queue is empty')
  }

  submitForm(controller, target, formData) {
    console.log('Implement in subclass: Submit form')
  }
}
